.popup__title {
    margin: 0;
    padding-bottom: 48px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

@media screen and (max-width: 600px) {
    .popup__title {
        padding-bottom: 75px;
        font-size: 18px;
        line-height: 22px;
    }
}
