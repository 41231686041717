.popup__save-button {
    width: 100%;
    height: 50px;
    margin-top: 43px;
    padding: 0;
    border: 0;
    background: #000000;
    border-radius: 2px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    transition: opacity 0.3s;
}

.popup__save-button:hover {    
    opacity: 0.8;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .popup__save-button      {
        height: 40px;
        margin-top: 45px;
        font-size: 14px;
        line-height: 17px;
    }
}
