.popup__image {
    width: 120px;
    height: 120px;
    margin: 26px auto 32px;
}

@media screen and (max-width: 600px) {
    .popup__image {
        margin: 16px auto 40px;
    }
}