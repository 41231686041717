.profile__name {
    max-width: calc(100% - 46px);
    margin: 0 0 16px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media screen and (max-width: 920px) {
    .profile__name {
        font-size: 27px;
        line-height: 33px;
    }
}

@media screen and (max-width: 600px) {
    .profile__name {
        margin-left: 18px;
        margin-bottom: 14px;
    }
}
