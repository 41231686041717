.element__trash-button {
    width: 18px;
    height: 19px;
    padding: 0;
    border: 0;
    background: url(../../../images/__trash.svg) center no-repeat;
    background-size: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: opacity 0.3s;
}

.element__trash-button:hover {
    opacity: 0.6;
    cursor: pointer;
}