.loading__indicator {
    color: white;
}

.loading__indicator::after {
    content: '';
    animation: loading 1s infinite;
}

@keyframes loading {
    0% {
        content: '.';
    }
    33% {
        content: '..';
    }
    66% {
        content: '...';
    }
    100% {
        content: '';
    }
}