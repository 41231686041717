.header {
    width: 100%;
    height: 119px;
    padding-top: 45px;
    border-bottom: 1px solid #545454;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .header {
        max-width: 100%;
        height: 84px;
        padding: 28px 30px 0;
        margin: 0;
    }
}
