.popup__container {
    width: 430px;
    height: 330px;
    border-radius: 10px;
    margin: auto;
    padding: 34px 36px 37px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 25px 0px #000000;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    z-index: 2;
}

@media screen and (max-width: 600px) {
    .popup__container {
        max-width: 282px;
        max-height: 322px;
        padding: 25px 22px 25px;
    }
}