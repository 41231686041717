.profile__avatar-edit {
    width: 120px;
    height: 120px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background: rgb(0, 0, 0, 0.8) url(../../../images/__avatar-edit.svg) 26px no-repeat;
    background-position: center;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity 0.2s;
    cursor: pointer;
}

.profile__avatar-edit:hover {
    opacity: 1;
}