.profile__field {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 600px) {
    .profile__field {
        flex-direction: column;
        align-items: center;
    }
}
