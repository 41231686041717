.footer {
    width: 100%;
    padding-bottom: 60px;
}

@media screen and (max-width: 600px) {
    .footer {
        padding-bottom: 36px;
    }
}
