.main {    
    width: 100%;
    padding-top: 40px;
    box-sizing: border-box;
}

@media screen and (max-width: 600px) {
    .main {
        padding-top: 42px;
    }
}
