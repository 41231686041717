.popup__input-warning {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FF0000;
    position: absolute;
    opacity: 0;
    transition: opacity 0.4s;
}