.header__button {
    display: none;
}

@media screen and (max-width: 600px) {
    .header__button {
        display: block;
        width: 24px;
        height: 21px;
        margin: 0;
        padding: 0;
        border: none;
        background: url(../../../images/__options.svg);
    }
}