.elements {
    width: 100%;
    display: grid;
    padding-bottom: 67px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 20px 17px;
}

@media screen and (max-width: 920px) {
    .elements {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .elements {
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 48px;
    }
}
