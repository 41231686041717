.footer__paragraph {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #545454;
}

@media screen and (max-width: 600px) {
    .footer__paragraph {
        font-size: 14px;
        line-height: 17px;
    }
}