.conductor__button {
    width: 358px;
    height: 50px;
    margin: 0 0 15px;
    padding: 0;
    border: none;
    border-radius: 2px;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    cursor: pointer;
    transition: opacity 0.3s;
}

.conductor__button:hover {
    opacity: 0.85;
}

@media screen and (max-width: 600px) {
    .conductor__button {
        max-width: 260px;
    }
}