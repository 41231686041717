.popup__save-button_disabled {
    background: none;
    border: 1px solid #000000;
    color: #000000;
    opacity: 0.2;
}

.popup__save-button_disabled:hover {    
    opacity: 0.2;
    cursor: default;
}
