.root {
    min-height: 100vh;
    background-color: black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}