.profile__edit-button {
    width: 24px;
    height: 24px;
    margin: 15px 0 0 18px;
    padding: 0;
    border: 0;
    background: url(../../../images/__edit-button.svg) center no-repeat;
    background-size: 100%;
    transition: opacity 0.3s;
}

.profile__edit-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width: 920px) {
    .profile__edit-button {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        margin-top: 8px;
    }
}
