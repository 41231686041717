.element__reaction-button {
    width: 22px;
    height: 35px;
    padding: 0;
    border: 0;
    background: url(../../../images/__reaction-button.svg) top no-repeat;
    background-size: 100%;
    padding-top: 19px;
    transition: opacity 0.3s;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.element__reaction-button:hover {
    opacity: 0.5;
    cursor: pointer;
}