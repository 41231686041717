.profile__mission {
    width: 100%;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media screen and (max-width: 920px) {
    .profile__mission {
        font-size: 14px;
        line-height: 17px;
    }
}

@media screen and (max-width: 600px) {
    .profile__mission {
        text-align: center;
        margin-bottom: 36px;
    }
}
