.conductor__input {
    width: 358px;
    height: 27px;
    margin: 0 0 30px;
    padding: 0;
    background: none;
    border: none;
    border-bottom: 2px solid #CCCCCC;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #CCCCCC;
}

.conductor__input:focus {
    outline: none;
}

.conductor__input:nth-of-type(2n) {
    margin-bottom: 216px;
}

@media screen and (max-width: 600px) {
    .conductor__input {
        max-width: 260px;
    }
}